import { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

function DisplayScore({score}) {
  return (
    <Row className="dino my-2">
      <Col xs="auto"><img src={`/nft/${score.tokenid}/background.png`} /></Col>
      <Col xs="auto" className="ms-1 leaderboard-text">#{score.tokenid}</Col>
      <Col className="text-end leaderboard-text">{score.score}</Col>
    </Row>
  )
}

function Leaderboard() {
  const [daily, setDaily] = useState([]);
  const [alltime, setAlltime] = useState([]);
  useEffect(() => {
    fetch('https://dopedinos-ynywvyqo6a-uc.a.run.app/top_scores').then(request => request.json()).then(d => {
      setDaily(d.daily);
      setAlltime(d.alltime);
    })
  }, [])
  return (
    <div id="leaderboard">
      <Row className="mb-5">
        <Col lg={6}>
          <Image src="/promo.gif" id="promo" class="my-5" />
        </Col>
        <Col lg={3} className="text-center">
          <h5>All time top scores</h5>
          {alltime.map((score) => {
            return <DisplayScore score={score} />
          })}
        </Col>
        <Col lg={3} className="text-center">
          <h5>Todays top scores</h5>
          {daily.map((score) => {
            return <DisplayScore score={score} />
          })}
        </Col>
      </Row>
    </div>
  );
}

export default Leaderboard;
