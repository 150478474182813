require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractABI = require("../contract-abi.json");
const contractAddress = "0x78c47630366f6bfc03c537bceac6d49e24dfe7d5";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 Write a message in the text-field above.",
        dinos: await getDinosForWallet(),
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: <PleaseInstallMetamask />
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        
        return {
          address: addressArray[0],
          dinos: await getDinosForWallet(),
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: <PleaseInstallMetamask />,
    };
  }
};

function PleaseInstallMetamask() {
  return (
    <span>
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    </span>
  );
}

const initializeContract = async () => {
  if (window.contract) {
    return window.contract
  } else {
    return await new web3.eth.Contract(contractABI, contractAddress);
  }
}

export const mintNft = async (numDinos) => {
  if (!window.contract) {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  }
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: web3.utils.toHex(web3.utils.toWei((numDinos * 0.01).toString(), 'ether')),
    data: window.contract.methods
      .rescue(numDinos)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}
export const getDinosForWallet = async () => {
  if (!window.contract) {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  }
  const inWallet = await window.contract.methods.walletOfOwner(window.ethereum.selectedAddress).call();
  return inWallet
}
