class Background {
  constructor(name, root, images) {
    this.images = images.map(image => {
      return {...image, name: name + "-" + image.name, source: root + image.source};
    });
  }

  getBackgrounds() {
    return this.images.filter(i => i.isBackground);
  }
}

export const SIMPLE = new Background("simple", "/parallax_backgrounds/simple/", [
  {name: "sky", source: "background.png", scrollFactor: 0, isBackground: true},
  {name: 'obstacle', source: 'obstacle.png', isObstacle: true},
]);

export const DESERT_1 = new Background("desert1", "/parallax_backgrounds/desert_1/", [
  {name: "sky", source: "desert_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "desert_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "desert_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "desert_bge.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "desert_bgd.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const DESERT_2 = new Background("desert2", "/parallax_backgrounds/desert_2/", [
  {name: "sky", source: "desert2_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "desert2_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "desert2_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "desert2_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "desert2_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const DESERT_3 = new Background("desert3", "/parallax_backgrounds/desert_3/", [
  {name: "sky", source: "desert_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "desert_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "desert_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "desert_bge.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "desert_bgd.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const DESERT_4 = new Background("desert4", "/parallax_backgrounds/desert_4/", [
  {name: "sky", source: "desert_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "desert_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "desert_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "desert_bge.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "desert_bgd.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},,
]);

export const GREEN_FOREST = new Background("greenforest", "/parallax_backgrounds/green_forest/", [
  {name: "sky", source: "green_forest_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "green_forest_bgb.png", scrollFactor: 0.5, isBackground: true},
  {name: "cactus", source: "green_forest_bgc.png", scrollFactor: 0.9, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const GREEN_MOUNTAINS = new Background("greenmountains", "/parallax_backgrounds/green_mountains/", [
  {name: "sky", source: "green_montains_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "green_montains_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "green_montains_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "green_montains_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "green_montains_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const ICE_MOUNTAINS = new Background("icemountains", "/parallax_backgrounds/ice_mountains/", [
  {name: "sky", source: "ice_montains_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "ice_montains_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "ice_montains_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "ice_montains_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "ice_montains_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const ICE_MOUNTAINS_PINK = new Background("icemountainspink", "/parallax_backgrounds/ice_mountains_pink/", [
  {name: "sky", source: "ice_pink_montains_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "ice_pink_montains_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "ice_pink_montains_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "ice_pink_montains_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "ice_pink_montains_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const PLAINS = new Background("plains", "/parallax_backgrounds/plains/", [
  {name: "sky", source: "plains_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "plains_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "plains_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "plains_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "plains_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const PLAINS_RED = new Background("plainsred", "/parallax_backgrounds/plains_red/", [
  {name: "sky", source: "red_plains_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "red_plains_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "red_plains_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "red_plains_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "red_plains_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const PLAINS_RED_2 = new Background("plainsred", "/parallax_backgrounds/plains_red_2/", [
  {name: "sky", source: "red_plains_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "red_plains_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "red_plains_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "red_plains_bgd.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const SWAMP = new Background("swamp", "/parallax_backgrounds/swamp/", [
  {name: "sky", source: "swamp_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "swamp_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "swamp_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "swamp_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "swamp_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

export const SWAMP_NIGHT = new Background("swamp_night", "/parallax_backgrounds/swamp_night/", [
  {name: "sky", source: "swamp_night_bga.png", scrollFactor: 0, isBackground: true},
  {name: "cactus_far", source: "swamp_night_bgb.png", scrollFactor: 0.1, isBackground: true},
  {name: "cactus", source: "swamp_night_bgc.png", scrollFactor: 0.4, isBackground: true},
  {name: "clouds", source: "swamp_night_bgd.png", scrollFactor: 0.9, isBackground: true},
  {name: "ground", source: "swamp_night_bge.png", scrollFactor: 0.8, isBackground: true},

  {name: 'obstacle_1', source: 'obstacle_1.png', isObstacle: true},
  {name: 'obstacle_2', source: 'obstacle_2.png', isObstacle: true},
  {name: 'obstacle_3', source: 'obstacle_3.png', isObstacle: true},
]);

class Assets {
  constructor(backgrounds) {
    this.backgrounds = backgrounds;
    this.index = 0;
  }

  getCurrentBackground() {
    return this.backgrounds[this.index];
  }

  allImages() {
    let allImages = [];
    this.backgrounds.forEach(b => {
      allImages = allImages.concat(b.images);
    });
    return allImages;
  }

  sampleObstacleName() {
    const obstacles = this.getCurrentBackground().images.filter(i => i.isObstacle);
    const randomObstacle = obstacles[Math.floor(Math.random() * obstacles.length)];
    return randomObstacle.name;
  }

  currentMapWidth() {
    if (this.index === 0) {
      return 2000;
    }
    return 15000;
  }

  incrementBackground() {
    this.index = (this.index + 1) % this.backgrounds.length;
  }

  currentBackgroundImages() {
    return this.getCurrentBackground().images;
  }
}

export default new Assets([SIMPLE, DESERT_1, DESERT_2, DESERT_3, DESERT_4, GREEN_FOREST, GREEN_MOUNTAINS, ICE_MOUNTAINS, ICE_MOUNTAINS_PINK, PLAINS, PLAINS_RED, PLAINS_RED_2, SWAMP, SWAMP_NIGHT])
