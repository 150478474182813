import md5 from 'blueimp-md5';
const postScore = (h, tokenId, gameState) => {
  const score = gameState.totalDistance;
  const hash_key = md5(tokenId.toString() + score.toString() + h);

  return fetch('https://dopedinos-ynywvyqo6a-uc.a.run.app/scores', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      tokenid: parseInt(tokenId, 10),
      score: score,
      startedat: gameState.startedat,
      endedat: Date.now(),
      hash_key: hash_key,
    })
  }).then(response => response.json());
}

export default postScore;