import { useContext, useState } from 'react';
import game from '../game';
import { IonPhaser } from '@ion-phaser/react'
import WalletContext from '../context';
import { Row, Col, Container } from 'react-bootstrap';

function SelectADino ({dinos, setSelectedDino, small, refresh}) {
  return (
    <Row>
      {dinos.map(d => {
        return (
          <Col onClick={() => {
            if (refresh) {
              window.location.reload();
            }
            setSelectedDino(d)
          }} xs="auto" className={`${small ? 'small' : ''} dino me-3`}>
            <img src={`/nft/${d}/background.png`} /><br/>
            <div className="text-center mt-2">#{d}</div>
          </Col>
        );
      })}
    </Row>
  )
}

function Game() {
  const { walletState, statusState, dinosState } = useContext(WalletContext)
  const [wallet, _] = walletState;
  const [dinos, setDinos] = dinosState;
  const [selectedDino, setSelectedDino] = useState(null);
  if (!wallet) {
    return <div id="game-select" className="text-center">
      <Container>
        <h5 className="mt-5 pt-5">Please connect with Metamask so we can find your dinos!</h5>
      </Container>
    </div>
  }
  if (dinos.length === 0) {
    return <div id="game-select" className="text-center">
      <Container>
        <h5 className="mt-5 pt-5">You must rescue a dino before playing!</h5>
      </Container>
    </div>
  }
  if (selectedDino || (!!dinos && dinos.length === 1)) {
    if (!window.selectedDino) {
      window.selectedDino = selectedDino || dinos[0];
    }
    return (
      <div id="game" className="text-center">
        <IonPhaser game={game} />
        <Container>
          <h3 className="text-start my-5">Change dinos</h3>
          <SelectADino small={true} refresh={true} dinos={dinos} setSelectedDino={setSelectedDino} />
        </Container>
      </div>
    );
  }
  return (
    <div id="game-select" className="text-center">
      <Container>
        <h3 className="text-start my-5">Which dino do you want to rescue?</h3>
        <SelectADino dinos={dinos} setSelectedDino={setSelectedDino} />
      </Container>
    </div>
  );
}

export default Game;
