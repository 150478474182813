import { Row, Col, Container, Image } from 'react-bootstrap';

function Roadmap() {
  return (
    <div id="roadmap">
      <h1 className="mb-5 text-center">Whats Next?</h1>
      <Container>
        <ul class="timeline">
          <li class="timeline-item">
            <div class="timeline-info"><span>Launch</span></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info"><span>25% Minted</span></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <p>15 random dinos are given away to random Dino Bros holders</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info"><span>50% Minted</span></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <p>Another 10 random dinos are given away</p>
              <p>Community input for another dino game with even more prizes</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info"><span>75% Minted</span></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <p>Another 5 random dinos are given away to random dino holders</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info"><span>100% Minted</span></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <p>The top 5 all time dino rescue scores get a free dino and their score is saved to the blockchain forever!</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info"><span>Future</span></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <p>Early access + prime real estate in an NFT gallery app we're working on</p>
              <p>Merch? Members exclusive space? Donations? You tell us!</p>
            </div>
          </li>
        </ul>

      </Container>
    </div>
  );
}

export default Roadmap;
