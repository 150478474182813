import { useState } from "react";
import { Button, Image, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function PreviewDino({id}) {
  return (
      <Card className="shadow preview-dino">
        <Card.Img variant="top" src={`/nft/${id}/background.png`} />
        <Card.Body>
          <Card.Title>#{id}</Card.Title>
        </Card.Body>
      </Card>
  );
}
const drawImage = Math.floor(Math.random() * 10000);

function Wtf() {
  const [position, setPosition] = useState(0);
  return (
    <div id="wtf">
      <Container>
        <Row className="mb-5">
          <Col lg={4}>
            <div style={{transform: "rotate(-20deg)", width: "100%", border: "10px solid black"}}><Image style={{width: "100%", border: "10px solid white"}} src="/game_screenshots/5-1024x512.png" /></div>
          </Col>
          <Col lg={4}>
            <div style={{transform: "rotate(-5deg) scale(1.4)", width: "100%", border: "10px solid black"}}><Image style={{width: "100%", border: "10px solid white"}} src="/game_screenshots/4-1024x512.png" /></div>
          </Col>

          <Col lg={4}>
            <div style={{transform: "rotate(10deg) scale(1.2)", width: "100%", border: "10px solid black"}}><Image style={{width: "100%", border: "10px solid white"}} src="/game_screenshots/2-1024x512.png" /></div>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md={6}>
            <h3>What are dino bros?</h3>
            <p>10000 dinos are trying to escape extinction on the Ethereum blockchain!</p>
            <p>Help rescue your dino by playing Dino Dash.</p>
            <p>A daily and all time leaderboard will be shown, and the 5 all time top scores will have their scores saved onto the blockchain forever.</p>
          </Col>
          <Col md={6}>
            <div id="minigame" className="pt-5">
              <div>
                <img height="100px" className="ms-4" src="/dino.gif"></img>
                <div className="d-grid gap-2">
                  <Button className="no-border-radius" href="/game" size="lg" variant="outline-dark">PLAY NOW</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md={6}>
            <div id="minigame">
              <div>
                <img height="200px" className="ms-4" src={`/nft/${drawImage}/transparent.png`}></img>
                <div className="d-grid gap-2">
                  <Button className="no-border-radius" href="https://www.raremints.club/c/m/0x78C47630366f6bfC03C537bCeaC6d49E24dFe7d5" size="lg" variant="outline-dark">COME DRAW WITH US</Button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <h3>A Community Art Project</h3>
            <p>Dino Bros are an experiment in NFT's to see what kind of cool stuff we can build together.</p>
            <p>Being an owner of a Dino Bro allows you to access our exclusive community canvas.</p>
          </Col>
        </Row>
      </Container>
      <div className="text-center preview-row my-5">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((id) => {
          return <PreviewDino id={id} />
        })}
      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Wtf;
