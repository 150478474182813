import { useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

function ProfileCard({url, name, description}) {
  const [cls, setCls] = useState(false);
  return (
    <Card className={`pointer p-4 mb-3 animate__animated ${cls && 'animate__pulse'}`} onMouseOver={() => {
      setCls(true);
    }} onMouseOut={() => {
      setCls(false);
    }}>
      <Image className="profile-photo" src={url} />
      <h3 className="mt-4">{name}</h3>
      {description}
    </Card>
  );
}
function MeetTheTeam() {
  return (
    <div id="meet-the-team">
      <Container>
        <h1 className="mb-2 text-center">Meet the team</h1>
        <div className="mb-5 text-center">We're a couple of game developers who like to build cool things.</div>
        <Row>
          <Col md={6}>
            <ProfileCard
              url="/nft/0/background.png"
              name="Chris"
              description="Extremely mediocre game developer"
            />
          </Col>
          <Col md={6}>
            <ProfileCard
              url="/nft/1/background.png"
              name="Marisa"
              description="Somewhat good at drawing"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MeetTheTeam;
