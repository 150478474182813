import Phaser from 'phaser';

import ParallaxScene from './scenes/ParallaxScene';

const config = {
  type: Phaser.AUTO,
  width: 1280,
  height: 720,
  physics: {
    default: 'arcade',
    arcade: {
      // debug: true,
      gravity: { y: 6000 }
    }
  },
  scene: [ParallaxScene]
}

export default config;