import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css";
import './App.css';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Header, { Navbar } from './components/Header'
import Game from './components/Game'
import Wtf from './components/Wtf'
import Roadmap from './components/Roadmap'
import MeetTheTeam from './components/MeetTheTeam'
import WalletContext from './context'
import Footer from './components/Footer';


function App() {
  return (
    <WalletContext.Provider value={{
      walletState: useState(""),
      statusState: useState(""),
      dinosState: useState([]),
    }}>
      <Router>
        <Switch>
          <Route path="/game">
            <div className="App">
              <Navbar />
              <Game />
              <Footer />
            </div>
          </Route>
          <Route path="/">
            <div className="App">
              <Header />
              <Wtf />
              <Roadmap />
              <MeetTheTeam />
              <Footer />
            </div>
          </Route>
        </Switch>
      </Router>
    </WalletContext.Provider>
  );
}

export default App;
