import Phaser, {Keyboard} from 'phaser';
import map from './map';
import postScore from './utils';

const STARTING_GAME_LOOP_SPEED = 4;
const MIN_SPAWN_RATE = 300;
const HOLD_TO_JUMP_LIMIT = 10;
const JUMP_POWER = -1000;
const HOLD_TO_JUMP_POWER = 5;
export default class ParallaxScene extends Phaser.Scene {
  constructor() {
    super("parallax-scene");
    this.map = map;
  }

  preload() {
    this.map.allImages().forEach((i) => {
      const img = this.load.image(i.name, i.source);
    })
    //window.selectedDino
    this.load.image('dino-1', `/nft/${window.selectedDino}/transparent.png`);
    this.load.image('dino-2', `/nft/${window.selectedDino}/left_leg_smaller.png`);
    this.load.image('dino-3', `/nft/${window.selectedDino}/right_leg_smaller.png`);
  }

  initializeGameState() {
    this.gameState = {
      jumpTimer: 0,
      cleanedup: false,
      startedat: Date.now(),
      mapDistance: 0,
      totalDistance: 0,
      speed: 6,
      isJumping: false,
      backgroundsByName: {},
      obstacles: [],
      maxObstacles: 2,
      aggressiveness: 0.05,
      maxSpawnRate: 1000,
      over: false,
      isPlayingEndGameLoop: false,
      isPlayingStartGameLoop: true,
    };
  }

  createScore() {
    this.scoreText = this.add.text(this.game.config.width - 10, 10, "0", { font: "bold 32px prstartk", fill: "black" }).setOrigin(1, 0)
  }

  createGround() {
    this.physics.world.setBounds(0, 0, this.game.config.width + 100, this.game.config.height);
  }

  createColliders() {
    this.physics.add.collider(this.player, this.platform);
  }

  createCursors() {
    this.cursors = this.input.keyboard.createCursorKeys();
  }

  createBackground() {
    for (let name in this.gameState.backgroundsByName) {
      const background = this.gameState.backgroundsByName[name];
      background.sprite.destroy();
    }

    this.gameState.backgroundsByName = {};
    this.map.getCurrentBackground().images.filter(i => i.isBackground).forEach((i) => {
      this.gameState.backgroundsByName[i.name] = {
        sprite: this.add.tileSprite(0, 0, this.game.config.width, this.game.config.height, i.name).setOrigin(0,0),
        scrollFactor: i.scrollFactor
      }
    })
  }

  bringToFront() {
    this.children.bringToTop(this.player);
    this.children.bringToTop(this.scoreText);
  }

  createPlayer() {
    this.anims.create({
      key: 'running',
      frames: [
        { key: 'dino-2' },
        { key: 'dino-3' },
      ],
      frameRate: 15,
      repeat: -1
    });
    this.anims.create({
      key: 'jumping',
      frames: [
        { key: 'dino-1' },
      ],
      frameRate: 15,
      repeat: -1
    });

    this.player = this.physics.add.sprite(0, this.game.config.height / 2, 'dino-1').play('running').setOrigin(0, 1);
    this.player.body.setSize(170, 180, 5, 5);
    //this.player.setGravityY(1000);
    this.player.setScale(0.5);
    this.player.setCollideWorldBounds(true);
  }

  createClickListener() {
    this.input.on('pointerdown', this.click, this)
  }

  create() {
    this.initializeGameState();
    this.createBackground();
    this.createScore();
    this.createGround();
    this.createPlayer();
    this.createCursors();
    this.createColliders();
    this.createClickListener();
  }

  click() {
    if (this.gameState.over) {
      this.map.index = 0;
      this.scene.restart();
    }
  }

  incrementLevel() {
    this.map.incrementBackground();
    this.createBackground();
    this.bringToFront();
    this.destroyAllObstacles();
    this.gameState.isPlayingStartGameLoop = true;
    this.gameState.isPlayingEndGameLoop = false;
    this.player.x = 0;
    this.player.y = this.game.config.height;
    this.gameState.mapDistance = 0;
    this.gameState.speed += 1; 
    this.gameState.aggressiveness += 0.02;
    this.gameState.maxSpawnRate = Math.max(MIN_SPAWN_RATE, this.gameState.maxSpawnRate - 100);
  }

  update(time, delta) {
    this.player.body.onFloor() && this.player.play('running', true);
    if (this.gameState.isPlayingStartGameLoop && this.player.x >= 200) {
      this.gameState.isPlayingStartGameLoop = false;
      this.player.x = 200;
    } else if (this.gameState.isPlayingStartGameLoop) {
      this.player.x += STARTING_GAME_LOOP_SPEED;
    } else if (this.gameState.isPlayingEndGameLoop && this.player.x > (this.game.config.width - 20)) {
      this.incrementLevel();
    } else if (this.gameState.isPlayingEndGameLoop && this.player.x <= this.game.config.width) {
      this.player.x += this.gameState.speed;
    } else if (!this.gameState.over) {
      const speed = this.gameState.speed;

      this.gameState.totalDistance += speed;
      this.scoreText.setText(this.gameState.totalDistance.toString())
      this.gameState.mapDistance += speed;
      for (let key in this.gameState.backgroundsByName) {
        const sprite = this.gameState.backgroundsByName[key].sprite;
        const scrollFactor = this.gameState.backgroundsByName[key].scrollFactor;
        sprite.tilePositionX += speed * delta/ 10 * scrollFactor;
      }
      this.updateEnemies(delta);

      // If getting close to the end stop spawning
      if (this.gameState.mapDistance < this.map.currentMapWidth() - (this.game.config.width / 2)) {
        this.spawnEnemies(time, delta);
      }
      // Implement hold ot jump
      if (this.cursors.up.isDown || this.cursors.space.isDown || this.input.activePointer.isDown) {
        if (this.player.body.onFloor() && this.gameState.jumpTimer === 0) {
          this.gameState.jumpTimer = 1;
          this.player.setVelocityY(JUMP_POWER);
          this.player.play('jumping', true);
        } else if (this.gameState.jumpTimer > 0 && this.gameState.jumpTimer < HOLD_TO_JUMP_LIMIT) {
          // keep jumping higher
          this.gameState.jumpTimer++;
          this.player.setVelocityY(JUMP_POWER + (this.gameState.jumpTimer * HOLD_TO_JUMP_POWER));
        }
      } else {
        this.gameState.jumpTimer = 0;
      }

      // If past the end, just keep walking past the end
      if (this.gameState.mapDistance > this.map.currentMapWidth()) {
        this.gameState.isPlayingEndGameLoop = true;
      }
    }
  }

  updateEnemies(delta) {
    this.gameState.obstacles.forEach((o) => {
      if (o.sprite.x < 0) {
        o.sprite.destroy();
      }
    });
    for (let i = 0; i < this.gameState.obstacles.length; i++) {
      const o = this.gameState.obstacles[i];
      o.sprite.x -= this.gameState.speed * delta/ 10;
    }
  }

  gameOver() {
    if (this.gameState.cleanedup) {
      return;
    }
    this.gameState.cleanedup = true;
    this.gameState.over = true;
    this.scoreText.destroy();
    this.physics.world.setBounds(0, 0, this.game.config.width + 100, this.game.config.height + 1000);
    this.add.text((this.game.config.width / 2), 100, 'GAME OVER', { font: "bold 32px prstartk", fill: "black" }).setOrigin(0.5);
    const done = () => {
      this.add.text((this.game.config.width / 2), 150, `Your score ${this.gameState.totalDistance} has been recorded!`, { font: "bold 32px prstartk", fill: "black" }).setOrigin(0.5);
      const restartText = this.add.text((this.game.config.width / 2), (this.game.config.height / 2), 'Restart?', { font: "bold 32px prstartk", fill: "black" })
      restartText.setOrigin(0.5);
    }
    postScore('dope-ass-dinos', window.selectedDino, this.gameState).then(done.bind(this));
  }

  spawnObstacle(time) {
    const obstacle = this.physics.add.sprite(this.game.config.width, this.game.config.height, this.map.sampleObstacleName()).setOrigin(0, 1)
    obstacle.body.setSize(obstacle.body.width / 2, obstacle.body.height / 2, 1, 1);


    obstacle.setCollideWorldBounds(true);

    this.physics.add.collider(obstacle, this.platform);
    this.physics.add.collider(obstacle, this.player, this.gameOver.bind(this));
    this.gameState.obstacles.push({
      sprite: obstacle,
      spawnedAt: time
    })
  }

  destroyAllObstacles() {
    this.gameState.obstacles.forEach(o => o.sprite.destroy());
  }

  spawnEnemies(time, delta) {
    if (this.gameState.obstacles.length === 0) {
      if (Math.random() < this.gameState.aggressiveness) {
        this.spawnObstacle(time);
      }
      return;
    }
    const lastTime = this.gameState.obstacles[this.gameState.obstacles.length-1];
    if (time - lastTime.spawnedAt > this.gameState.maxSpawnRate) {
      if (Math.random() < this.gameState.aggressiveness) {
        this.spawnObstacle(time);
      }
    }
  }
} 
