import { useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { connectWallet, getCurrentWalletConnected } from '../utils';
import WalletContext from "../context";

export default function ConnectToMetamask () {
  const { walletState, statusState, dinosState } = useContext(WalletContext)
  const [walletAddress, setWallet] = walletState;
  const [status, setStatus] = statusState;
  const [dinos, setDinos] = dinosState;
  useEffect(async () => {
    const { address, status, dinos } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);
    setDinos(dinos);
  }, []);


  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  return <div>
    {walletAddress && (
      <Button
        className="no-border-radius"
        disabled={true}
        variant="outline-dark">
        {walletAddress.substr(0, 10)}...
      </Button>
    )}
    {!walletAddress && (
      <Button
        className="no-border-radius"
        onClick={connectWalletPressed}
        variant="outline-dark">
        Connect to Metamask
      </Button>
    )}
  </div>
}