import { useState } from 'react';
import { Row, Col, Container, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faEthereum, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Leaderboard from './Leaderboard';
import { mintNft } from '../utils';
import ConnectToMetamask from './ConnectToMetamask';
import { Link } from "react-router-dom";


export function Navbar() {
  return (
    <nav className="pt-4">
      <Container>
        <Row>
          <Col xs="auto">
            <Link to="/"><Image src="/dino.gif" height="50px" /></Link>
          </Col>
          <Col className="text-center sections pt-3">
            <a href="/#wtf">WTF?</a>
            <Link to="/game">Play Now</Link>
            <a href="/#roadmap">Roadmap</a>
            <a href="/#meet-the-team">Team</a>
            <a href="https://www.raremints.club/c/m/0x78C47630366f6bfC03C537bCeaC6d49E24dFe7d5">Draw</a>
          </Col>
          <Col xs="auto" className="text-end social-icons">
            <a href="https://discord.gg/84eS2UrD"><FontAwesomeIcon icon={faDiscord} /></a>
            <a href="https://twitter.com/DinoBrosNFT"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://polygonscan.com/address/0x78c47630366f6bfc03c537bceac6d49e24dfe7d5"><FontAwesomeIcon icon={faEthereum} /></a>
          </Col>
          <Col xs="auto" className="text-end pt-1">
            <ConnectToMetamask />
          </Col>
        </Row>
      </Container>
    </nav>
  );
}

function Header() {
  const [number, setNumber] = useState(1);
  return (
    <div className="header">
      <Container>
        <Navbar />
        <h1>DINO BROS NFT</h1>
        <h5>Help rescue 10000 dino bros before extinction!</h5>
        <h3 className="mt-4"><FontAwesomeIcon size="1x" style={{color: "#8348e5"}} className="mb-1" icon={faEthereum} />1 Each</h3>

        <div id="mint">
          <Button href="https://twitter.com/DinoBrosNFT" className="ms-5 no-border-radius" variant="dark">Rescue a Dino</Button>
          <h4 className="mt-3"></h4>
        </div>
        <Leaderboard />
      </Container>
    </div>
  );
}

export default Header;
