import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter, faEthereum } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <div id="footer">
      <Container>
        <Row>
          <Col xs="auto">
            <a href="/"><Image src="/dino.gif" height="70px" /></a>
          </Col>
          <Col xs="auto" className="text-center social-icons">
            <a href="https://discord.gg/84eS2UrD"><FontAwesomeIcon icon={faDiscord} /></a>
            <a href="https://twitter.com/DinoBrosNFT"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://polygonscan.com/address/0x78c47630366f6bfc03c537bceac6d49e24dfe7d5"><FontAwesomeIcon icon={faEthereum} /></a>
          </Col>
          <Col xs="auto" className="text-end">
            Use this art however you want
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Footer;
